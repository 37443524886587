import React from 'react';
import {graphql} from 'gatsby';
import {Container} from 'theme-ui';
import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01';
// import ModalSimple from '@solid-ui-blocks/Modal/Block02';
import Header from '@solid-ui-blocks/Header/Block01';
import Hero from '@solid-ui-blocks/Hero/Block02';
import Companies from '@solid-ui-blocks/Companies/Block01';
import Services from '@solid-ui-blocks/Features/Block05';
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01';
import WhyChooseUs from '@solid-ui-blocks/Features/Block01';
// import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block02';
import Strategies from '@solid-ui-blocks/Stats/Block01';
// import Testimonials from '@solid-ui-blocks/Testimonials/Block01';
import GetStarted from '@solid-ui-blocks/CallToAction/Block01';
// import Blog from '@solid-ui-blocks/Blog/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import {normalizeBlockContentNodes} from '@blocks-helpers';
import theme from './_theme';
import styles from './_styles';

const IndexPage = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout theme={theme} {...props}>
            <Seo title={'Accueil'} description={'Page d\'accueil du site de MOENN Technologies'} keywords={['Agence et Factory Digitale', 'conception des sites internet', 'e-commerce', 'développement des application web', 'développement des applications desktop', 'développement des application mobile', 'crm', 'erp', 'logiciels']} />
            {/* Modals */}
            <ModalWithTabs content={content['authentication']} reverse/>
            <ModalWithTabs content={content['contact']}/>

            {/* Blocks */}
            <Header content={content['header']}/>
            <Divider space='5'/>
            <Divider space='5'/>
            <Container variant='full' sx={styles.heroContainer}>
                <Hero alt={'Leader en transformation digitale'} content={content['hero']}/>
            </Container>
            <Divider space='3'/>
            <Companies content={content['companies']}/>
            <Divider space='5'/>
            <Divider space='5'/>
            <Services content={content['services']}/>
            <Divider space='5'/>
            <Divider space='5'/>
            <FeatureOne content={content['feature-one']}/>
            <Divider space='5'/>
            <Divider space='5'/>
            <Container variant='wide' sx={styles.whyChooseUsContainer}>
                <WhyChooseUs content={content['why-choose-us']}/>
            </Container>{}
            {/*
            <Divider space='5'/>
            <Divider space='5'/>
            <FeatureTwo content={content['feature-two']}/>
            */}
            {/*
            <Divider space='5'/>
            <Divider space='5'/>
            <Testimonials content={content['testimonials']}/>
            */}
            <Divider space='6'/>
            <Divider space='6'/>
            <Container variant='full' sx={styles.strategiesContainer}>
                <Divider space={-6}/>
                <Strategies content={content['strategies']}/>
            </Container>
            <Divider space='5'/>
            <Divider space='5'/>
            <GetStarted content={content['get-started']}/>
            {/*
            <Divider space='5'/>
            <Blog content={content['latest-blogs']}/>
            */}
            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}

export const query = graphql`
  query homepageInfinityBlockContent {
    allBlockContent(
      filter: { page: { in: ["infinity/index", "infinity/shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;
export default IndexPage;
